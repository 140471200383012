/** @jsx jsx */
/* eslint-disable react/prop-types */
import React from 'react'; // eslint-disable-line no-unused-vars
import { Helmet } from 'react-helmet';

import { jsx } from 'theme-ui';
import Nav from './Nav';
import Seo from './Seo';

const Layout = ({ children, maxWidth = "1440px", style }) => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap" rel="stylesheet" />
        <link href="http://fonts.cdnfonts.com/css/metropolis-2" rel="stylesheet" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Seo/>
      <Nav />
      <main
        style={{ maxWidth, ...style }}
        sx={{
          width: 'full',
          fontSize: [4, 5],
          color: (theme) => `${theme.colors.gray[8]}`,
          lineHeight: 'normal',
          px: [null, null, 6],
          mt: ['55px', '55px', '55px', '70px'],
          pt: 4,
          mx: 'auto',
        }}
      >
        {children}
        <footer
          sx={{
            fontSize: 1,
            textAlign: 'center',
            my: 8,
            color: (theme) => `${theme.colors.gray[6]}`,
          }}
        >
          &copy; Factly Media &amp; Research {new Date().getFullYear()}. All rights reserved.
        </footer>
      </main>
    </>
  );
};

export default Layout;
