import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const Seo =({ description, lang = "en", image = { src: "/dashboard.png" }, keywords = ["Factly", "Factly Dashboards"], title, pathname = "" }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaTitle = title || data.site.siteMetadata.title
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(metaImage ? [
                {
                  property: `og:image`,
                  content: metaImage
                },
                {
                  property: `og:image:alt`,
                  content: metaTitle,
                },
              ] : null)
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                  : [])
            }
          />
        )
      }}
    />
  )
}

export default Seo

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`